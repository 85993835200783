import styled from 'styled-components';
import { store, useAppSelector } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import axios from 'axios';
import Hls from 'hls.js';
import React, { useEffect, useRef, useState } from 'react';
import urlonStringify from '@/utils/urlon';
import { forEach } from 'lodash';
import throttle from 'lodash/throttle';
import { storedRouter } from '@/routes';
import { stringToSlug } from '@/utils/gameIdsToObjects';
import { closest } from '../../page-components/DataCarousel/lib/utils/dom/closest/closest';
import { addClass } from '../../page-components/DataCarousel/lib/utils/dom/addClass/addClass';

const SCROLL_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

type VideoReelProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const useDataSource = (sourceId: string) => {
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);
  const [state, setState] = React.useState<{ data?: any } | null>(null);

  React.useEffect(() => {
    axios
      .get(`${window.config.dataSourceApiUrl}/resolve/sources`, {
        headers: {
          Authorization: 'Bearer ' + authenticationToken,
        },
        params: {
          q: urlonStringify({ ids: [sourceId] }),
        },
      })
      .then((response) => {
        setState(response.data[sourceId]);
      })
      .catch(console.error);
  }, [authenticationToken]);

  return state;
};

const VideoReel = (componentProps: VideoReelProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const reelRef = useRef(null);
  const sectionsRef = useRef<(HTMLElement | null)[]>([]);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]); // Array of 3 video elements
  const [videos, setVideos] = useState([0, 1, 2]); // Track current and preloaded video indexes
  const [currentIndex, setCurrentIndex] = useState(0); // Track the index of the current video
  const [scrollDirection, setScrollDirection] = useState<
    typeof SCROLL_DIRECTION.UP | typeof SCROLL_DIRECTION.DOWN | null
  >(null); // Track scroll direction
  const dataResponse = useDataSource('video-reels-1');
  const videoSources = dataResponse?.data || [];
  const lastScrollY = useRef(0); // Store last scroll position
  const observer = useRef<IntersectionObserver | null>(null);
  const isMuted = useRef(true);
  const startingIndex = useRef(0);
  const isScrolledToPosition = useRef(false);
  const isInitialLoad = useRef(true); // Flag to prevent premature URL hash updates
  const viewPortHeight = window.innerHeight - 56;
  const isReelsTutorialSeen = useRef(false);
  isReelsTutorialSeen.current = localStorage.getItem('isReelsTutorialSeen') === 'true';

  // get url param hash
  const urlHash = window.location.hash;
  const urlHashGameId = urlHash.replace('#', '');

  const openGame = (index: number) => {
    const gameId = videoSources[index]?.gameId;
    const gameName = stringToSlug(videoSources[index]?.name);
    // console.log('Open game:', gameId);
    if (storedRouter?.navigate) {
      storedRouter.navigate('/video-reels/' + gameId + '/' + gameName);
      window.dispatchEvent(new PopStateEvent('popstate'));
      return;
    }
  };

  const handleAnimationPlayback = () => {
    const reelEl = document.getElementsByClassName('reel')[0];
    const startAnimationClass = 'start-animation-'; 

    const shouldHide = reelEl.querySelectorAll('.animate-on-scroll');
    shouldHide.forEach((el) => {
      el.classList.remove(`${startAnimationClass}logo`);
      el.classList.remove(`${startAnimationClass}text`);
      (el as HTMLElement).style.opacity = '0';
    });

    const snapElement = document.getElementsByClassName('snap')[currentIndex];
    if (snapElement) {
      const animatedElements = snapElement.querySelectorAll('.animate-on-scroll');
      animatedElements.forEach((el) => {
        const isElLogo = el.classList.contains('logo');
        const elAnimationClass = isElLogo ? `${startAnimationClass}logo` : `${startAnimationClass}text`;
        el.classList.remove(elAnimationClass);
        void (el as HTMLElement).offsetWidth;
        el.classList.add(elAnimationClass);
      });
    }
  };

// Function to detect if the device is iOS
const isIOS = () => {
  return /iPhone|iPad|iPod/.test(navigator.platform);
};

// Func\tion to detect if the browser is Safari (not Chrome or Firefox)
const isSafari = () => {
  const ua = navigator.userAgent;
  return ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('CriOS') && !ua.includes('FxiOS');
};

 const handleVideoPlayback = () => {
  // Get video element
  const video = document?.querySelector(`#section-${currentIndex} video.video`) as HTMLVideoElement;

  const videos = document.querySelectorAll(
    `#section-${currentIndex - 1} video.video, #section-${currentIndex + 1} video.video`
  );
  
  videos.forEach((video) => {
    (video as HTMLVideoElement).pause();
  });
  
  if (video && video.dataset.loadedIndex !== `${currentIndex + 1}`) {
    console.log('PLAY VIDEO! for ' + currentIndex);
    // Play the current video with sound
    // toggleSoundVideo(video, isMuted.current);
    video.play().catch((error: any) => {
      console.log(error);
      if (error.name === 'NotAllowedError') {
        console.warn('Autoplay blocked, user interaction required.');
      } else {
        console.error('Play failed:', error);
      }
    });

    video.dataset.oldLoadedIndex = video.dataset.loadedIndex; // Mark the video as loaded for the current index
    video.dataset.loadedIndex = `${currentIndex}`; // Mark the video as loaded for the current index

    // Get loading spinner element
    const spinner = document.querySelector(`.snap:nth-child(${currentIndex + 1}) .loading-spinner`) as HTMLElement;

    // Video event listeners for handling loading spinner
    video.addEventListener('loadeddata', () => {
      video.classList.remove('loading');
      spinner.style.display = 'none';
    });

    video.addEventListener('waiting', () => {
      spinner.style.display = 'block';
    });

    video.addEventListener('playing', () => {
      spinner.style.display = 'none';
    });

    video.addEventListener('error', (e) => {
      console.error(`Error loading video at index ${currentIndex}`);
      spinner.style.display = 'block';
    });


  }
};

const addSourceToVideo = (video: HTMLVideoElement, index: number) => {
  const videoSrc = videoSources[index]; // Change source index for all videoRefs
        
  if (isIOS() && isSafari() && !!videoSrc.hlsPath) {
    // Directly load HLS source for iOS Safari
    video.src = videoSrc.hlsPath;
    video.load();
  } else if (Hls.isSupported() && !!videoSrc.hlsPath) {
    // Use HLS.js for other browsers
    const hls = new Hls();
    hls.loadSource(videoSrc.hlsPath);
    hls.attachMedia(video);
    hls.on(Hls.Events.ERROR, (_event, data) => {
      console.error('HLS.js error', data);
      if (data.fatal) {
        hls.destroy();
        video.src = videoSrc.fallbackPath; // Switch to fallback if HLS fails
        video.load();
      }
    });
  } else {
    console.error('HLS is not supported.');
    // Use fallback source if HLS is not supported
    video.src = videoSrc.fallbackPath;
    video.load();
  }
};

  // this is a fix for Safari address bar issue on iOS
  const setReelHeight = () => {
    const reelElement = document.getElementsByClassName('reel')[0];
    if (reelElement) {
      (reelElement as HTMLElement).style.height = viewPortHeight + 'px';

      const reelOverlayElement = document.getElementsByClassName('reel-overlay-element');
      forEach(reelOverlayElement, (el) => {
        (el as HTMLElement).style.height = viewPortHeight + 'px';
      });
    }
  };

  const updateHashDirectly = (hash: any) => {
    if (window.location.hash !== `#${hash}`) {
      // Temporarily remove the hash to ensure change is detected
      window.location.replace(`${window.location.href.split('#')[0]}#${hash}`);
      
      // Manually dispatch the hashchange event
      setTimeout(() => {
        window.dispatchEvent(new HashChangeEvent('hashchange'));
      }, 50);
    }
  };
  

  const setSpawnPoint = () => {
    const reelElement = document.getElementsByClassName('reel')[0];
    const targetSection = sectionsRef.current[startingIndex.current];
  
    if (reelElement && targetSection) {
      // console.log('Scrolling to:', viewPortHeight * startingIndex.current);
      reelElement.scrollTop = viewPortHeight * startingIndex.current;
      isScrolledToPosition.current = true;
      isInitialLoad.current = false; // Mark initial load as complete
    } else if (!isScrolledToPosition.current) {
      // Retry if the target section is not available yet
      // console.log('Target section not found or reel element is missing, retrying...');
      setTimeout(setSpawnPoint, 100); // Retry after a short delay
    }
  };

  const updateNearbyClasses = (index: number) => {
    if (index === -1) return;
  
    // Get all sections
    const sections = document.querySelectorAll('.snap');
  
    // Remove `.nearby` from all sections
    sections.forEach((section) => section.classList.remove('nearby'));
  
    // Add `.nearby` to sections within the range [index - 4, index + 4]
    for (let offset = -4; offset <= 4; offset++) {
      const nearbyIndex = index + offset;
      const section = document.querySelector(`#section-${nearbyIndex}`);
      if (section) {
        section.classList.add('nearby');
      }
    }
  };

  if (videoRefs.current.length === 0) {
    videoRefs.current = Array.from({ length: 5 }, () => {
      const video = document.createElement('video');
      video.className = 'video splitted loading has-shadow';
      video.loop = true;
      video.playsInline = true;
      video.muted = isMuted.current;
      return video;
    });
  }

  const addSection = (index: number, startWithPopulatedVideo?: boolean) => {
    // console.log('Adding video section:', sectionsRef);
    if (document.getElementById(`section-${index}`) || !videoSources[index]) return;

    const reelElement = reelRef.current;
    const section = document.createElement('section');
    section.className = 'snap nearby';
    section.id = `section-${index}`; // Assign a unique ID
    // sectionsRef.current[index] = section;

    // @todo: refactor this to use styled-components
    const videoContainer = document.createElement('div');
    videoContainer.className = 'video-container';
    
    const backgroundOverlay = document.createElement('div');
    backgroundOverlay.className = 'background-overlay';
    backgroundOverlay.style.backgroundImage = `url(${videoSources[index].backgroundImagePath})`;


    const spinner = document.createElement('div');
    spinner.className = 'loading-spinner';
    const ripple1 = document.createElement('div');
    const ripple2 = document.createElement('div');
    spinner.appendChild(ripple1);
    spinner.appendChild(ripple2);
    videoContainer.appendChild(spinner);

    const imgLogo = document.createElement('img');
    imgLogo.className = 'logo animate-on-scroll';
    imgLogo.srcset = videoSources[index].headerLogoPath ?? '';

    const imgLogoText = document.createElement('img');
    imgLogoText.className = 'logo-text animate-on-scroll';
    imgLogoText.srcset = videoSources[index].headerLogoTextPath ?? '';

    const videoSrc = videoSources[index];
    const imgThumbnail = document.createElement('img');
    imgThumbnail.className = 'thumbnail has-shadow';
    
    imgThumbnail.srcset = videoSrc.hlsPath.replace('playlist.m3u8', 'thumbnail.jpg');

    handleAnimationPlayback();

    const reelOverlayRefElement = document.getElementsByClassName('reel-overlay-element')[0];

    // clone reelDataRefElement and add index to class name
    const reelOverlayRefElementClone = reelOverlayRefElement.cloneNode(true) as HTMLElement;

    reelOverlayRefElementClone.className = `reel-overlay-el-${index}`;

    // add videoSrc title to cloned reelOverlayRefElementClone
    const reelOverlayRefElementCloneTitle = reelOverlayRefElementClone.querySelector('.reel-data-ref-info-title');
    if (reelOverlayRefElementCloneTitle) {
      reelOverlayRefElementCloneTitle.textContent = videoSrc.name;
    }

    const headerWrapper = document.createElement('div');
    const videoWrapperClone = headerWrapper.cloneNode(true) as HTMLElement;
    
    headerWrapper.className = 'video-wrapper header-wrapper';
    headerWrapper.appendChild(imgLogo);
    headerWrapper.appendChild(imgLogoText);
    videoContainer.appendChild(backgroundOverlay);
    videoContainer.appendChild(headerWrapper);
    
    videoWrapperClone.className = 'video-wrapper game-wrapper';
    videoWrapperClone.appendChild(imgThumbnail);

    if (startWithPopulatedVideo) {
      const video = videoRefs.current[index];
      if (video) {
        console.log('append video for ' + index);
        videoWrapperClone.appendChild(video);
        addSourceToVideo(video, index);
        video.play();
        video.dataset.loadedIndex = `${index}`;
      }
    }


    videoContainer.appendChild(videoWrapperClone);

    // Append elements to the DOM
    videoContainer.appendChild(reelOverlayRefElementClone);
    section.appendChild(videoContainer);

    // Add event listeners after elements are appended
    const toggleSoundVideoRefEl = section.querySelectorAll('.toggle-sound-video');
    toggleSoundVideoRefEl.forEach((el) => {
      el.addEventListener('click', () => {
        const videos = document.querySelectorAll('video.video') as unknown as NodeListOf<HTMLVideoElement>;
        const setVolumeState = !isMuted.current;
        forEach(videos, (video) => {
          // console.log('SOUND TOGGLE');
          toggleSoundVideo(video, setVolumeState);
        });
      });
    });

    const playButtonRefEl = section.querySelectorAll('.eko-button');
    playButtonRefEl.forEach((el) => {
      el.addEventListener('click', () => {
        openGame(index);
      });
    });

    if (reelElement) {
      (reelElement as HTMLElement).appendChild(section);
    } else {
      console.error('reelElement is null, cannot append section.');
    }
  };

  const toggleSoundVideo = (video: HTMLVideoElement, isMutedNewValue: boolean, updateRef: boolean = true) => {
    const muteEls = document.getElementsByClassName('mute-video');
    const unmuteEls = document.getElementsByClassName('unmute-video');

    isMuted.current = updateRef ? isMutedNewValue : isMuted.current;
    video.muted = isMutedNewValue;

    if(updateRef) {
      forEach(muteEls, (el, index) => {
        (el as HTMLElement).style.display = isMutedNewValue ? 'none' : 'block';
        (unmuteEls[index] as HTMLElement).style.display = isMutedNewValue ? 'block' : 'none';
      });
    }
  };
  
  // Use a separate effect to trigger the scroll after the elements have loaded
  useEffect(() => {
    if (urlHashGameId && videoSources.length > 0) {
      const index = videoSources.findIndex((video: { gameId: string; }) => video.gameId === urlHashGameId);
      // console.log('Computed starting index based on URL hash:', index);
      if (index !== -1) {
        startingIndex.current = index;
        if (!isScrolledToPosition.current) {
          // Delay the scroll slightly to ensure sections are available
          requestAnimationFrame(setSpawnPoint);
        }
      } else {
        console.log('URL hash game ID not found in video sources');
      }
    } else {
      console.log('No URL hash or video sources not loaded');
    }
  }, [urlHashGameId, videoSources]);
  

  useEffect(() => {
    // Set initial height
    setReelHeight();
  
    // Track loaded sections to avoid redundant additions
    const loadedSections = new Set();
  
    // Add event listeners for window resize and orientation change
    window.addEventListener('resize', setReelHeight);
    window.addEventListener('orientationchange', setReelHeight);
  
    const reelElement = document.getElementsByClassName('reel')[0];

    const handleScroll = throttle(() => {
      const currentScrollY = reelElement.scrollTop;
      if (currentScrollY > lastScrollY.current) {
        setScrollDirection(SCROLL_DIRECTION.DOWN);
      } else if (currentScrollY < lastScrollY.current) {
        setScrollDirection(SCROLL_DIRECTION.UP);
      }
      lastScrollY.current = currentScrollY;

      // Fallback check: Ensure observer aligns with visible section
      const sections = document.querySelectorAll('section');

      const closestIndex = Array.from(sections).findIndex((section) => {
        if (section) {
          const rect = section.getBoundingClientRect();
          return rect.top >= 0 && rect.bottom <= window.innerHeight;
        }
        return false;
      });
      if (closestIndex !== -1 && closestIndex !== currentIndex) {
        setCurrentIndex((prevIndex) => {
          if (prevIndex !== closestIndex) {
            return closestIndex;
          }
          return prevIndex;
        });
        updateHashDirectly(videoSources[closestIndex].gameId);

        console.log('loadNext ' + closestIndex);
        loadNextSection(closestIndex);
      }

      // Update nearby classes
      updateNearbyClasses(closestIndex);
    }, 100); // Adjust delay as needed

    
    const processSectionAtIndex = (index: number) => {
      addSection(index);
    
      const videoContainer = document.querySelector(`#section-${index}`)?.querySelector('.game-wrapper');
      const videoExists = videoContainer?.querySelector('video');
      const video = videoRefs.current[index % 5];
    
      if (video && videoContainer) {
        if (!videoExists) {
          videoContainer.appendChild(video);
        }
        addSourceToVideo(video, index);
        handleVideoPlayback();
        video.dataset.oldLoadedIndex = video.dataset.loadedIndex;
        video.dataset.loadedIndex = `${index}`;
      }
    
      loadedSections.add(index);
    };
    
    // Function to load the next section in sequence based on scroll direction
    const loadNextSection = (currentIndex: number) => {
      if (scrollDirection === SCROLL_DIRECTION.DOWN) {
        if (currentIndex >= 2) {
          for (let i = 1; i <= 2; i++) {
            const nextIndex = currentIndex + i;
            if (nextIndex < videoSources.length && !loadedSections.has(nextIndex)) {
              processSectionAtIndex(nextIndex);
            }
          }
        }
      } else if (scrollDirection === SCROLL_DIRECTION.UP) {
        for (let i = 1; i <= 2; i++) {
          const prevIndex = currentIndex - i;
          if (prevIndex >= 0) {
            processSectionAtIndex(prevIndex);
          }
        }
      }
    };
  
    if (reelElement) {
      // console.log('Reel element found, setting up scroll listener');
      reelElement.addEventListener('scroll', handleScroll);    
  
      // Find starting index if urlHashGameId is provided
      if (urlHashGameId) {
        const index = videoSources.findIndex((video: { gameId: string; }) => video.gameId === urlHashGameId);
        // console.log('Observer computed starting index based on URL hash:', index);
        if (index !== -1) {
          startingIndex.current = index;
        }
      }
  
      // Ensure all sections up to and including the starting index are added
      // const sliceMax = Math.min(startingIndex.current + 2, videoSources.length);
      const sliceMax = 5;
      const sliceMin = 0;
      // console.log('********Adding sections from :', sliceMin + ' to ' + sliceMax);
      videoSources.slice(sliceMin, sliceMax).forEach((_: any, index: number) => {
        if (document.querySelectorAll('section').length <= index) {
          addSection(sliceMin + index, true);
          loadedSections.add(sliceMin + index); // Mark as loaded
        }
      });
  
      // Call setSpawnPoint only after ensuring sections are loaded
      setTimeout(() => {
        if (!isScrolledToPosition.current &&  document.querySelector(`#section-${startingIndex.current}`)) {
          setSpawnPoint();
        }
      }, 100); // Small delay to ensure sections are added
  
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {

              // const index = sectionsRef.current.indexOf(entry.target as HTMLElement);
              // get section index by entry.target.id
              const index = parseInt((entry.target as HTMLElement).id.split('-')[1]);
              // Log the ID of the current section
              // console.log('Current section ID:', entry.target.id);
              // console.log('Intersection observed for index:', index);
              if (!isInitialLoad.current) { // Only update hash after initial load
                  setCurrentIndex(index);
                  const video = (entry.target?.querySelector('video.video') as HTMLVideoElement);
                  if (video) {
                    video.play();
                  }
    
                  // Update URL hash with the current game ID for bookmarking
                  updateHashDirectly(videoSources[index].gameId);
                }
  
              // Always ensure the last section is added if we are near the end
              if (index >= videoSources.length - 2 && !loadedSections.has(videoSources.length - 1)) {
                // console.log("Adding the last section as we are near the end of the list");
                loadedSections.add(videoSources.length - 1); // Mark last section as loaded
              }
  
              // Disconnect and observe only the next section in sequence
              observer.current?.disconnect(); // Disconnect the current observer

              // Dynamically select the next element to observe
              const nextIndex = index + 1;
              const nextElement = document.querySelector(`#section-${nextIndex}`);

              if (nextElement) {
                observer.current?.observe(nextElement); // Observe the actual DOM element
                // console.log(`Now observing section: #section-${nextIndex}`);
              } else {
                console.warn(`Section with ID #section-${nextIndex} not found.`);
              }
            }
          });
        },
        {
          root: reelElement,
          threshold: 0.9,
        },
      );
  
      // Start observing the initial section in sequence
      const initialSection = document.querySelector(`#section-${startingIndex.current}`);

      if (initialSection) {
        observer.current.observe(initialSection); // Observe the actual DOM element
        // console.log(`Now observing initial section: #section-${startingIndex.current}`);
      } else {
        console.warn(`Initial section with ID #section-${startingIndex.current} not found.`);
      }
  
      return () => {
        reelElement.removeEventListener('scroll', handleScroll);
        observer.current?.disconnect();
        window.removeEventListener('resize', setReelHeight);
        window.removeEventListener('orientationchange', setReelHeight);
      };
    } else {
      console.error(`Element with class ${props.className} not found.`);
    }
  }, [videoSources, scrollDirection, props]);  

  useEffect(() => {
    function simulateSwipe() {
      const indexAtStart = startingIndex.current;
      const handTutorial = document.querySelector('.swipe-up-container');
      if (handTutorial) {
        handTutorial.classList.add('show-animation');
      }

      const scrollContainers = document.querySelectorAll('.snap');
      const initialScrollEl = scrollContainers[startingIndex.current];
      const nextScrollEl = scrollContainers[startingIndex.current + 1];

      if (scrollContainers) {
        // Animate scrolling down
        nextScrollEl?.scrollIntoView({
          behavior: 'smooth',
        });

        if (handTutorial) {
          handTutorial.classList.add('swipe-up');
        }

        // Scroll back to the original position after a delay
        setTimeout(() => {
          initialScrollEl.scrollIntoView({
            behavior: 'smooth',
          });
        }, 2000); // Adjust timing for the delay

        const handContainer = document.querySelector('.circle-container');
        // Listen for the animationend event
        handContainer?.addEventListener('animationend', function handleAnimationEnd(event) {
          const animationEvent = event as AnimationEvent;
          // Do something when the animation is finished
          if (animationEvent.animationName === 'swipeUpDownHand') {
            handTutorial?.classList.remove('show-animation');
            // wait 3s and if user has not scrolled, trigger swipe
            setTimeout(() => {
              if (startingIndex.current === indexAtStart) {
                simulateSwipe();
              }
            }, 3000);
          }
          // Remove the event listener after it has been called once
          handContainer?.removeEventListener('animationend', handleAnimationEnd);
        });
      }
    }

    // Trigger the swipe simulation automatically after a short delay
    // add to local storage to prevent multiple triggers
    if (!isReelsTutorialSeen.current) {
      localStorage.setItem('isReelsTutorialSeen', 'true');
      setTimeout(() => {
        simulateSwipe();
      }, 1000);
    }
  }, [startingIndex, currentIndex, videos]);

  // When currentIndex changes then handle video playback
  useEffect(() => {
    handleAnimationPlayback();
    handleVideoPlayback();
  }, [currentIndex]);

  const contextValue = React.useMemo(() => {
    return {
      name: 'VideoReel',
    };
  }, [videoSources, currentIndex]);

  return (
    <ModuleElementDiv className={props.className} $styleText={props.styleText}>
      <div className="reel" ref={reelRef}></div>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default VideoReel;
